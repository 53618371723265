import React from 'react';
import { useState, useEffect } from 'react';
import {
    Button, Card, Input, message, Modal, Radio, Typography
} from 'antd';
import GithubCorner from 'react-github-corner';
import logo from '../assets/logo.png';
import '../assets/App.css';
import * as log from 'loglevel';
import callkitConfig from '../callkit.config.js';

let CallKitSDK
if (process.env.NODE_ENV === 'development') {
    CallKitSDK = require('../callkit')
    CallKitSDK.enableDebug()
    log.enableAll()
} else {
    CallKitSDK = require('callkit')
    CallKitSDK.disableDebug()
    log.disableAll()
}

// TODO, update to github web callkit
const customHref = 'https://github.com/AgoraIO-Community/ag-iot-callkit-wechat-app'
const { Paragraph } = Typography;

function App() {
    const [deviceId, setDeviceId] = useState('');
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [attachMsg, setAttachMsg] = useState('');
    const [userData, setUserData] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isLogin, setIsLogin] = useState(false);
    const [currentAudioCodec, setCurrentAudioCodec] = useState('');

    useEffect(() => {
        getAudioCodec();

        const peerHangupEventCallbackUnsubscribe = CallKitSDK.getCallkitManager().peerHangupEventCallback((event) => {
            log.debug('peerHangupEventCallback invoked', event);
            message.success('Peer hung up.');
            setIsModalVisible(false);
            setIsLoading(false);
            setAttachMsg('');
        });

        const peerBusyEventCallbackUnsubscribe = CallKitSDK.getCallkitManager().peerBusyEventCallback((event) => {
            log.debug('peerBusyEventCallback invoked', event);
            message.success('Peer is busy.');
            setIsModalVisible(false);
            setIsLoading(false);
            setAttachMsg('');
        });

        const peerAnswerEventCallbackUnsubscribe = CallKitSDK.getCallkitManager().peerAnswerEventCallback((event) => {
            log.debug('peerAnswerEventCallback invoked', event);
            message.success('Peer answered.');
            setIsLoading(false);
        });

        const peerRequestEventCallbackUnsubscribe = CallKitSDK.getCallkitManager().peerRequestEventCallback((event) => {
            log.debug('peerRequestEventCallback invoked', event);
            setIsModalVisible(true);
            const attachMsg = event.attachMsg || '';
            setAttachMsg(attachMsg);
        });

        const userSessionEndEventCallbackUnsubscribe = CallKitSDK.getAccountManager().userSessionEndEventCallback((event) => {
            log.debug('userSessionEndEventCallback Invoked. User session end and connection closed', event);
            setIsLogin(false);
        });

        // unsubscribe events
        return () => {
            peerHangupEventCallbackUnsubscribe();
            peerBusyEventCallbackUnsubscribe();
            peerAnswerEventCallbackUnsubscribe();
            peerRequestEventCallbackUnsubscribe();
            userSessionEndEventCallbackUnsubscribe();
        };
    }, []);

    const getUserData = () => {
        const userData = CallKitSDK.getAccountManager().getUserData();
        setUserData(userData);
    };

    const audioCodecOnChange = (e) => {
        const audioCodec = e.target.value;
        CallKitSDK.getCallkitManager().setAudioCodec(audioCodec);
        getAudioCodec();
    };

    const registerOnClick = () => {
        if (!username) {
            message.error('Please enter username.');
            return;
        }

        if (!password) {
            message.error('Please enter password.');
            return;
        }

        setIsLoading(true);
        CallKitSDK.getAccountManager().registerAccount(username, password)
            .then(() => {
                message.success('Register Success');
                setIsLoading(false);
            })
            .catch((err) => {
                message.error(`Register Failed! Message: ${err.message}`);
                setIsLoading(false);
            });
    };

    const loginOnClick = () => {
        if (!username) {
            message.error('Please enter username.');
            return;
        }

        if (!password) {
            message.error('Please enter password.');
            return;
        }

        const peerPlayerContainer = document.getElementById('peerPlayer');
        // const localPlayerContainer = document.getElementById('localPlayer');

        if (!peerPlayerContainer) {
            message.error('peerPlayerContainer is undefined.');
            return;
        }

        setIsLoading(true);
        CallKitSDK.getAccountManager().initSdk(callkitConfig, username, password, peerPlayerContainer, null)
            .then(() => {
                message.success('Login Success');
                setIsLogin(true);
                setIsLoading(false);
                getUserData();
            })
            .catch((err) => {
                message.error(`Login Failed! Message: ${err.message}`);
                setIsLoading(false);
            });
    };

    const logoutOnClick = () => {
        // release rtc and mqtt connections
        CallKitSDK.getAccountManager().destroySdk();
        setIsLogin(false);
        setIsLoading(false);
        message.success('Log out Success');
    }

    const callOnClick = () => {
        if (!deviceId) {
            message.error('Please enter device ID.');
            return;
        }

        const attachMsg = 'Hello world from web.';
        setIsLoading(true);

        CallKitSDK.getCallkitManager().callDevice(deviceId, attachMsg)
            .then(() => {
            }).catch((err) => {
                log.error(err);
                setIsLoading(false);
            });
    };

    const answerOnClick = () => {
        CallKitSDK.getCallkitManager().answerDevice()
            .then(() => {
                setIsModalVisible(false);
            }).catch((err) => {
                log.error(err);
            });
    };

    const hangupOnClick = () => {
        CallKitSDK.getCallkitManager().hangupDevice()
            .then(() => {
                setIsModalVisible(false);
                setIsLoading(false);
            }).catch((err) => {
                log.error(err);
            });
    };

    const setAudioCodec = (mode) => {
        CallKitSDK.getCallkitManager().setAudioCodec(mode);
    };

    const getAudioCodec = () => {
        const audioCodec = CallKitSDK.getCallkitManager().getAudioCodec();
        setCurrentAudioCodec(audioCodec);
    };

    const renderHelper = () => {
        if (isLogin) {
            return (
                <div className="wrapper">
                    <Input.Group style={{ marginTop: 8, marginBottom: 8 }}>
                        <Input
                            addonBefore="Username:"
                            disabled
                            style={{ width: 'calc(100% - 100px)' }} 
                            value={userData.username}
                        />

                        <Button
                            danger
                            onClick={logoutOnClick} 
                            style={{ width: 100 }}
                            type="primary"
                        >
                            Log Out
                        </Button>
                    </Input.Group>

                    <Input
                        addonBefore="Client ID:"
                        disabled
                        style={{ marginBottom: 8 }}
                        value={userData.clientId}
                    />

                    <Input
                        onChange={(e) => {
                            setDeviceId(e.target.value);
                        }}
                        placeholder="Please enter device ID"
                        style={{ width: 500, marginBottom: 8 }}
                        value={deviceId}
                    />

                    <Radio.Group
                        onChange={audioCodecOnChange}
                        style={{ width: 500, marginBottom: 8 }}
                        value={currentAudioCodec}
                    >
                        <Radio value="g722">
                            g722
                        </Radio>

                        <Radio value="pcmu">
                            g711u
                        </Radio>
                    </Radio.Group>

                    {/* <div>
                        <Button
                            onClick={() => {
                                CallKitSDK.getCallkitManager().enableLocalVideo(true);
                            }}
                            style={{ width: 168 }}
                        >
                            Enable Video
                        </Button>

                        <Button
                            onClick={() => {
                                CallKitSDK.getCallkitManager().enableLocalVideo(false);
                            }}
                            style={{ width: 168 }}
                        >
                            Disable Video
                        </Button>
                    </div>*/}

                    <div style={{ width: 500, marginTop: 16 }}>
                        <Button
                            loading={isLoading}
                            onClick={callOnClick}
                            style={{ width: 500, marginTop: 16 }}
                            type="primary"
                        >
                            Call
                        </Button>

                        <Button
                            danger
                            onClick={hangupOnClick}
                            style={{ width: 500, marginTop: 16 }}
                            type="primary"
                        >
                            Hangup
                        </Button>
                    </div>
                </div>
            );
        }

        return (
            <div className="wrapper">
                <Input
                    onChange={(e) => {
                        setUsername(e.target.value);
                    }}
                    placeholder="Please enter username"
                    style={{ width: 500, marginTop: 32 }}
                    value={username}
                />

                <Input
                    onChange={(e) => {
                        setPassword(e.target.value);
                    }}
                    placeholder="Please enter password"
                    style={{ width: 500, marginTop: 16 }}
                    value={password}
                />

                <Button
                    loading={isLoading}
                    onClick={registerOnClick}
                    style={{ width: 500, marginTop: 32 }}
                    type="primary"
                >
                    Register
                </Button>

                <Button
                    loading={isLoading}
                    onClick={loginOnClick}
                    style={{ width: 500, marginTop: 16 }}
                    type="primary"
                >
                    Login
                </Button>
            </div>
        );
    };

    const renderModalHelper = () => {
        return (
            <Modal
                cancelText="Hangup"
                centered
                mask={false}
                maskClosable={false}
                okText="Answer"
                onCancel={hangupOnClick}
                onOk={answerOnClick}
                title="Incoming call, please answer..."
                visible={isModalVisible}
            >
                <Paragraph>
                    Attached message: 
                    {' '}

                    {attachMsg}
                </Paragraph>

                <Paragraph>
                    Display preview from remote...
                </Paragraph>
            </Modal>
        )
    }

    return (
        <div className="App">
            <div className="App-header">
                <Card style={{ width: 500, backgroundColor: '#282c34' }}>
                    <img
                        alt="Agora logo"
                        src={logo}
                    />
                </Card>

                {renderHelper()}

                <div
                    className='playerContainer'
                    id="playerContainer"
                >
                    <div
                        id="peerPlayer"
                        style={{ width: 400, height: 400 }}
                    />

                    {/* <div
                        id="localPlayer"
                        style={{ width: 400, height: 400 }}
                    />*/}
                </div>

                {renderModalHelper()}

                <GithubCorner
                    bannerColor="#151513"
                    direction="right"
                    href={customHref}
                    octoColor="#ffffff"
                    size={100}
                    target="_blank" 
                />
            </div>
        </div>
    );
}

export default App;
